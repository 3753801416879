import axios from "axios";
import { dispatch } from "~/old-app/redux";
import { AuthActions } from "~/old-app/redux/auth-slice";
import { getEnv } from "~/old-app/utils/helpers/getEnv";

const client = axios.create({
  baseURL: getEnv().BASE_BE_API,
  validateStatus: (status) => {
    if (status === 401 || status === 403) {
      dispatch(AuthActions.logout());
      return false;
    }
    if (status >= 200 && status <= 300) return true;
    return false;
  },
  headers: {
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use(
  (config) => {
    console.log(
      `SERVER API: Method:${config.method}, Url:${
        (config.baseURL || "") + config.url
      }`
    );
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { client };



// ///////

// import axios from "axios";
// import {
//   buildKeyGenerator,
//   buildMemoryStorage,
//   setupCache,
// } from "axios-cache-interceptor";
// import { dispatch } from "~/old-app/redux";
// import { AuthActions } from "~/old-app/redux/auth-slice";
// import { getEnv } from "~/old-app/utils/helpers/getEnv";

// const instance = axios.create({
//   baseURL: getEnv().BASE_BE_API,
//   validateStatus: (status) => {
//     if (status === 401 || status === 403) {
//       dispatch(AuthActions.logout());
//       return false;
//     }
//     if (status >= 200 && status <= 300) return true;
//     return false;
//   },
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// const client = setupCache(instance, {
//   // generateKey: buildKeyGenerator((request) => ({
//   //   method: request.method,
//   //   url: request.url,
//   //   custom: `${request.url}-${JSON.stringify(request.params)}-${
//   //     request.method
//   //   }-${request.headers?.["district-id"]}-${request.headers?.["language"]}`,
//   // })),
//   // storage: buildMemoryStorage(),
//   ttl: 1000 * 60 * 5, // 5 minutes
// });

// client.interceptors.request.use(
//   (config) => {
//     console.log("cache => ", config.cache);
//     console.log(`API: Method:${config.method}, Url:${config.url}`);
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// export { client };
